import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import Login from './Pages/Login';
import routes from './Router/Router';
import {
  BrowserRouter,
  Switch,
  Route,
  Routes, 
  Redirect
} from "react-router-dom";
// import { Route } from "react-router-dom";
import Naacuplaod from './Pages/Naacupload';

function App() {
  // const rootElement = document.getElementById("root");
  const [token, settoken] = useState(false);

  const userdata = JSON.parse(window.localStorage.getItem('logindata'));

  useEffect(() => {
    if(userdata){
      settoken(userdata.token)
      console.log(userdata.token)
    }
}, []);
  return (
//  <Login></Login>
 <BrowserRouter>
 <Routes>
   <Route path="/" element={<Login />} />
   <Route path="login" element={<Login />} />
   {/* {token &&  */}
   <Route path="upload" element={<Naacuplaod />} />
   {/* } */}
 </Routes>
</BrowserRouter>
// rootElement
  );
}

export default App;
